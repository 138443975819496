import { useState, useEffect } from 'react';
import './Stats.css';
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

const Stats = ({ user, logout }) => {
  let [locations, setLocations] = useState([]);
  useEffect(() => {
    loadLocations();
  }, []);
  function loadLocations() {
    fetch("/getAllLocations")
      .then(res => {
        let response = res;

        return (response.json());
      })
      .then(response => {
        let final = response;

        setLocations(final.filter(l => l.name != ("Ana Hub Test")));

      })

      .catch(
        e => {

          alert("There was an error connecting to our server to load the locations. Please logout, login and try again. Error: " + e)
          logout()
        }
      )
  }

  let [users, setUsers] = useState([]);
  let [newUsers, setNewUsers] = useState([]);

  useEffect(() => {
    loadUsers();

  }, []);

  function loadUsers() {
    let data = {};
    fetch("/getAllUsers", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;

        return (response.json());
      })
      .then(response => {
        let final = response;
        //console.log(final)
        setUsers(final.filter(u => u.status !== "Deleted" && u.type !== "admin" && u.type !== "superAdmin"));

      })
      .catch(
        e => {

          alert("There was an error connecting to our server to load the users. Please logout, login and try again. Error: " + e)
          logout()
        }
      )


  }

  let [pickupSlots, setPickupSlots] = useState([]);
  useEffect(() => {
    loadPickupSlots();
    
  }, []);

  function loadPickupSlots() {
    // Fetch all locations, then fetch all pickup slots
    fetch("/getAllPickupSlots")
      .then(res => {
        return res.json();
      })
      .then(res => {
        let final = res;
        //console.log(final);

        setPickupSlots(final);
      })
      .catch(
        e => {
          //console.log("Error fetching pickup slots:" + e)
          alert("There was an error connecting to our server to load the pickup slots. Please logout, login and try again. Error: " + e)
          logout()
        }
      )

  }



  let [bikes, setBikes] = useState();
  useEffect(() => {
    loadBikes();
  }, []);


  function loadBikes() {
    let data = {};
    fetch("/getAllBikesNoPics", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => {
        let response = res;

        return (response.json());
      })
      .then(response => {
        let final = response;
        //console.log(final)
        setBikes(final.filter(b => b.status !== "Deleted"));


      })

      .catch(
        e => {
          alert("There was an error connecting to our server to load the bikes. Please logout, login and try again. Error: " + e)
          logout()
        }
      )


  }

  let [deliveredBikes, setDeliveredBikes] = useState();
  useEffect(() => {
    if (pickupSlots.length && bikes && users){
      loadDeliveredBikes();
      
    }
  }, [pickupSlots, bikes, users]);
  function loadDeliveredBikes() {
    if (bikes && locations && pickupSlots.length) {
      let delivered = bikes.filter(b => b.status === "Donated");
      //console.log(delivered);

      delivered.filter(b => {
        let user = users.filter(u => u.bike_id === b.id)[0];
        let spot;
        if (user){
          spot = pickupSlots.filter(s => s.id === user.pickup_slot)[0];
          b.user_gender = user.gender;
          b.user_age = user.age;
        }
        else {
          //console.log("No user found for bike: " + b.id);
        }

        if (spot)
          b.pickup_spot_year = new Date(spot.start_time).getFullYear();
        else {
          //console.log("No spot found for bike: " + b.id);
        }
       

      });
      setDeliveredBikes(delivered);
    }
  }

  let [bikesNeeded, setBikesNeeded] = useState([]);
  useEffect(() => {
    loadBikesNeeded();
  }, []);
  useEffect(() => {
    loadBikesNeeded();
  }, [bikes, users, locations]);
  function loadBikesNeeded() {
    if (bikes && users) {
      //find the sizes of bikes needed by looking into bike_size inside users
      let sizes = [];
      //remove the users with bike_id

      users.filter(u => !u.bike_id).filter(u => u.bike_size).forEach(u => {
        sizes.push(u.bike_size);
      });
      //Count the number of each size
      let needed = [];
      sizes.forEach(s => {
        let found = needed.filter(n => n.size === s)[0];
        if (found) {
          found.needed++;
        } else {
          needed.push({ size: s, needed: 1 });
        }
      });
      
      //Add the total to the end
      needed.push({ size: "Total", needed: sizes.length });
      //Order by the number of bikes needed
      needed.sort((a, b) => {
        return b.needed - a.needed;
      });
      setBikesNeeded(needed);

    }
  }

  let [bikesDonatedBySize, setBikesDonatedBySize] = useState([]);
  useEffect(() => {
    loadBikesDonatedBySize();
  }, []);
  useEffect(() => {
    loadBikesDonatedBySize();
  }, [bikes]);
  function loadBikesDonatedBySize() {
    if (bikes) {
      //find the sizes of bikes that were delivered by looking into bike_size inside bikes
      let sizes = [];
      bikes.filter(b => b.status === "Donated").forEach(b => {
        sizes.push(b.size);
      });

     
      //Count the number of each size
      let donated = [];
      sizes.forEach(s => {
        let found = donated.filter(n => n.size === s)[0];
        if (found) {
          found.donated++;
        } else {
          donated.push({ size: s, donated: 1 });
        }
      });
      
      //Add the total to the end
      donated.push({ size: "Total", donated: sizes.length+value2022 });
      //Order by the number of bikes needed
      donated.sort((a, b) => {
        return b.donated - a.donated;
      });
      setBikesDonatedBySize(donated);

    }
  }

  let [chartData, setChartData] = useState([10, 20, 30]);
  const data = {
    labels: ["Female", "Male", "Other"],
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          'lightgreen',
          'lightblue',
          'purple',
        ],
        size: 30,
        borderWidth: 1,
        //show the percentage of the pie


      },
    ],
  };
  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    loadChartData();
  }, []);
  useEffect(() => {
    loadChartData();
  }, [users]);

  function loadChartData() {
    let data = {};
    if (users) {
      data.female = users.filter(u => u.gender == "Female" && u.bike_id).length+value2022;
      data.male = users.filter(u => u.gender === "Male" && u.bike_id).length;
      data.other = users.filter(u => u.gender !== "Female" && u.gender !== "Male" && u.bike_id).length;
      setChartData([data.female, data.male, data.other])
    }

  };


  let value2022 = 212;
  return (
    <>
      <section id="content" className="wrapper">
        <h3>Statistics</h3>
        <div className="chart">
          <Pie data={data} />
        </div>
        <br />
        <h3>Yearly Statistics</h3>

        <table>
          <thead>
            <tr>
              <th className="headers">Year</th>
              <th className="headers">2022</th>
              <th className="headers">2023</th>
              <th className="headers">2024</th>
              <th className="headers">All Years</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>New Users (Children)</td>
              <td>{value2022} (0)</td>
              <td>{users ? users.filter(u => new Date(u.date_included).getFullYear() === 2023 && u.type === "recipient").length + " (" + users.filter(u => new Date(u.date_included).getFullYear() == 2023 && u.age < 19 && u.type === "recipient").length + ")" : "Loading..."}</td>
              <td>{users ? users.filter(u => new Date(u.date_included).getFullYear() === 2024 && u.type == "recipient").length + " (" + users.filter(u => new Date(u.date_included).getFullYear() === 2024 && u.age < 19 && u.type == "recipient").length + ")" : "Loading..."}</td>
              <td className="totals">{users ? value2022+users.filter(u => u.status !== "Deleted" && u.type === "recipient").length + " (" + users.filter(u => u.age < 19 && u.type === "recipient").length + ")" : "Loading..."}</td>
            </tr>
            <tr>
              <td>Bikes In the Program</td>
              <td>{value2022}</td>
              <td>{bikes ? bikes.filter(b => new Date(b.date_included).getFullYear() == 2023 && b.status != "Deleted").length : "Loading..."}</td>
              <td>{bikes ? bikes.filter(b => new Date(b.date_included).getFullYear() == 2024 && b.status != "Deleted").length : "Loading..."}</td>
              <td className="totals">{bikes ? bikes.filter(b => b.status === "Available" || b.status === "Donated" || b.status === "Reserved" || b.status === "Waiting for Release").length+value2022 : "Loading..."}</td>
            </tr>
            <tr>
              <td className="totals">Delivered Bikes</td>
              <td className="totals">{value2022}</td>
              {deliveredBikes ? <>
                <td className="totals">{deliveredBikes.filter(d => d.pickup_spot_year === 2023).length}</td>
                <td className="totals">{deliveredBikes.filter(d => d.pickup_spot_year === 2024).length}</td>
                <td className="totals">{deliveredBikes.length+value2022}</td>
              </> : "Loading..."}
            </tr>
          </tbody>
        </table>

        {/* <h3>Delivered Bikes Per Year of Inclusion in the Database</h3>
        <table>
          <thead>
            <tr>
              <th className="headers">Year</th>
              <th className="headers">2023</th>
              <th className="headers">2024</th>
              <th className="headers">All Years</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td># Females (children)</td>

              <td>{users ? users.filter(u => u.bike_id && u.gender === "Female" && new Date(u.date_included).getFullYear() === 2023).length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender === "Female" && new Date(u.date_included).getFullYear() === 2023).length + ")" : "Loading..."}</td>
              <td>{users ? users.filter(u => u.bike_id && u.gender === "Female" && new Date(u.date_included).getFullYear() === 2024).length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender === "Female" && new Date(u.date_included).getFullYear() === 2024).length + ")" : "Loading..."}</td>
              <td className="totals">{users ? users.filter(u => u.bike_id && u.gender === "Female").length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender === "Female").length + ")" : "Loading..."}</td>
            </tr>

            <tr>
              <td># Males (Children)</td>

              <td>{users ? users.filter(u => u.bike_id && u.gender === "Male" && new Date(u.date_included).getFullYear() === 2023).length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender === "Male" && new Date(u.date_included).getFullYear() === 2023).length + ")"
                : "Loading..."}</td>
              <td>{users ? users.filter(u => u.bike_id && u.gender === "Male" && new Date(u.date_included).getFullYear() === 2024).length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender === "Male" && new Date(u.date_included).getFullYear() === 2024).length + ")"
                : "Loading..."}</td>
              <td className="totals">{users ? users.filter(u => u.bike_id && u.gender === "Male").length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender === "Male").length + ")" : "Loading..."}</td>
            </tr>
            <tr>
              <td># Others (Children)</td>

              <td>{users ? users.filter(u => u.bike_id && u.gender !== "Male" && u.gender !== "Female" && new Date(u.date_included).getFullYear() === 2023).length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender !== "Male" && u.gender !== "Female" && new Date(u.date_included).getFullYear() === 2023).length + ")"
                : "Loading..."}</td>
              <td>{users ? users.filter(u => u.bike_id && u.gender !== "Male" && u.gender !== "Female" && new Date(u.date_included).getFullYear() === 2024).length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender !== "Male" && u.gender !== "Female" && new Date(u.date_included).getFullYear() === 2024).length + ")"
                : "Loading..."}</td>
              <td className="totals">{users ? users.filter(u => u.bike_id && u.gender !== "Male" && u.gender !== "Female").length + " (" + users.filter(u => u.age < 19 && u.bike_id && u.gender !== "Male" && u.gender !== "Female").length + ")" : "Loading..."}</td>
            </tr>

            <tr>
              <td className="totals"># Total (Children)</td>
              <td className="totals">{users ? users.filter(u => u.bike_id && new Date(u.date_included).getFullYear() === 2023).length + " (" + users.filter(u => u.age < 19 && u.bike_id && new Date(u.date_included).getFullYear() === 2023).length + ")"
                : "Loading..."}</td>
              <td className="totals">{users ? users.filter(u => u.bike_id && new Date(u.date_included).getFullYear() === 2024).length + " (" + users.filter(u => u.age < 19 && u.bike_id && new Date(u.date_included).getFullYear() === 2024).length + ")"
                : "Loading..."}</td>
              <td className="totals">{users ? users.filter(u => u.bike_id).length + " (" + users.filter(u => u.age < 19 && u.bike_id).length + ")" : "Loading..."}</td>
            </tr>
          </tbody>
        </table> */}

        <h3>Users That Got Bikes</h3>
        <table>
          <thead>
            <tr>
              <th className="headers">Year</th>
              <th className="headers">2022</th>
              <th className="headers">2023</th>
              <th className="headers">2024</th>
              <th className="headers">All Years</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Females (children)</td>
              <td>{value2022} (0)</td>
              <td>{deliveredBikes ? deliveredBikes.filter(b => b.user_gender==="Female"&& b.pickup_spot_year ===2023).length +" ("+deliveredBikes.filter(b => b.user_gender==="Female"&& b.user_age<18 && b.pickup_spot_year ===2023).length +")": "Loading..."}</td>
              <td>{deliveredBikes ? deliveredBikes.filter(b => b.user_gender==="Female"&& b.pickup_spot_year ===2024).length +" ("+deliveredBikes.filter(b => b.user_gender==="Female"&& b.user_age<18 && b.pickup_spot_year ===2024).length +")": "Loading..."}</td>
              <td className="totals">{deliveredBikes ? deliveredBikes.filter(b => b.user_gender==="Female").length+value2022 +" ("+deliveredBikes.filter(b => b.user_gender==="Female"&& b.user_age<18 ).length +")": "Loading..."}</td>
              
            </tr>

            <tr>
              <td>Males (children)</td>
              <td>0 (0)</td>
              <td>{deliveredBikes ? deliveredBikes.filter(b => b.user_gender==="Male"&& b.pickup_spot_year ===2023).length +" ("+deliveredBikes.filter(b => b.user_gender==="Male"&& b.user_age<18 && b.pickup_spot_year ===2023).length +")": "Loading..."}</td>
              <td>{deliveredBikes ? deliveredBikes.filter(b => b.user_gender==="Male"&& b.pickup_spot_year ===2024).length +" ("+deliveredBikes.filter(b => b.user_gender==="Male"&& b.user_age<18 && b.pickup_spot_year ===2024).length +")": "Loading..."}</td>
              <td className="totals">{deliveredBikes ? deliveredBikes.filter(b => b.user_gender==="Male").length +" ("+deliveredBikes.filter(b => b.user_gender==="Male"&& b.user_age<18 ).length +")": "Loading..."}</td>
            </tr>
            <tr>
              <td>Others (children)</td>
              <td>0 (0)</td>
              <td>{deliveredBikes ? deliveredBikes.filter(b => b.user_gender!=="Female" && b.user_gender!=="Male"&& b.pickup_spot_year ===2023).length +" ("+deliveredBikes.filter(b => b.user_gender!=="Female" && b.user_gender!=="Male"&& b.user_age<18 && b.pickup_spot_year ===2023).length +")": "Loading..."}</td>
              <td>{deliveredBikes ? deliveredBikes.filter(b => b.user_gender!=="Female" && b.user_gender!=="Male"&& b.pickup_spot_year ===2024).length +" ("+deliveredBikes.filter(b => b.user_gender!=="Female" && b.user_gender!=="Male"&& b.user_age<18 && b.pickup_spot_year ===2024).length +")": "Loading..."}</td>
              <td className="totals">{deliveredBikes ? deliveredBikes.filter(b => b.user_gender!=="Female" && b.user_gender!=="Male").length +" ("+deliveredBikes.filter(b => b.user_gender!=="Female" && b.user_gender!=="Male"&& b.user_age<18).length +")": "Loading..."}</td>
            </tr>

            <tr>
              <td className="totals">Total (children)</td>
              <td className="totals">{value2022} (0)</td>
              <td className="totals">{deliveredBikes ? deliveredBikes.filter(b =>b.pickup_spot_year ===2023).length +" ("+deliveredBikes.filter(b => b.user_age<18 && b.pickup_spot_year ===2023).length +")": "Loading..."}</td>
              <td className="totals">{deliveredBikes ? deliveredBikes.filter(b => b.pickup_spot_year ===2024).length +" ("+deliveredBikes.filter(b => b.user_age<18 && b.pickup_spot_year ===2024).length +")": "Loading..."}</td>
              <td className="totals">{deliveredBikes ? (value2022+deliveredBikes.length)+" ("+(deliveredBikes.filter(b => b.user_age<18).length) +")": "Loading..."}</td>
            </tr>
          </tbody>
        </table>

        <h3>Bikes per Location</h3>
        <table>
          <thead>
            <tr>
              <th className="headers">Location</th>
              <th className="headers">Available</th>
              <th className="headers">Waiting for Release</th>
              <th className="headers">Delivered</th>
              <th className="headers">Total</th>
            </tr>
          </thead>
          <tbody>{locations.map(l => {
              return (
                l.name==="Gateway Bicycle Hub"?
                <tr key={l.name}>
                  {l.name==="All Locations" ? <td className="totals">{l.name}A</td> : <td>{l.name}</td>}
                  <td>{bikes ? bikes.filter(b => b.location == l.id && b.status === "Available").length : "Loading..."}</td>
                  <td>{bikes ? bikes.filter(b => b.location == l.id && b.status === "Waiting for Release").length : "Loading..."}</td>
                  <td>{bikes ? value2022+bikes.filter(b => b.location == l.id && b.status === "Donated").length : "Loading..."}</td>
                  <td className="totals">{bikes ? value2022+bikes.filter(b => b.location == l.id).length : "Loading..."}</td>
                </tr>
                 :
                <tr key={l.name}>
                <td>{l.name}</td>
                <td>{bikes ? bikes.filter(b => b.location == l.id && b.status === "Available").length : "Loading..."}</td>
                <td>{bikes ? bikes.filter(b => b.location == l.id && b.status === "Waiting for Release").length : "Loading..."}</td>
                <td>{bikes ? bikes.filter(b => b.location == l.id && b.status === "Donated").length : "Loading..."}</td>
                <td className="totals">{bikes ? bikes.filter(b => b.location == l.id).length : "Loading..."}</td>
              </tr>
              )
            }
            )}
            <tr>
              <td>All Locations</td>
              <td className="totals">{bikes ? bikes.filter(b => b.status === "Available").length : "Loading..."}</td>
              <td className="totals">{bikes ? bikes.filter(b => b.status === "Waiting for Release").length : "Loading..."}</td>
              <td className="totals">{bikes ? value2022+bikes.filter(b => b.status === "Donated").length : "Loading..."}</td>
              <td className="totals">{bikes ? bikes.length+value2022 : "Loading..."}</td>
            </tr>
          </tbody>
        </table>

        

        <h3>Bikes Needed (sizes)</h3>

        <table>
          <thead>
            <tr>
              <th className="headers">Size</th>
              <th className="headers"># of Bikes Needed</th>
            </tr>
          </thead>
          <tbody>{
            bikesNeeded ? bikesNeeded.map(b => (
              <tr key={b.size}>
                <td>{b.size}</td>
                <td>{b.needed}</td>
              </tr>
            )) : null
          }</tbody>
        </table>

        <h3>Bikes Delivered (sizes)</h3>
        <table>
          <thead>
            <tr>
              <th className="headers">Size</th>
              <th className="headers"># of Bikes Delivered</th>
            </tr>
          </thead>
          <tbody>
            {bikesDonatedBySize ? bikesDonatedBySize.map(b => (
              <tr key={b.size}>
                <td>{b.size}</td>
                <td>{b.donated}</td>
              </tr>
            )) : null}
            <tr>
              <td>N.A. (2022)</td>
              <td>{value2022}</td>
              </tr>
          </tbody>
          </table>

      </section>
      <br /><br />
    </>
  )
}
export default Stats;